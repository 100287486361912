import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { Navigate } from "react-router-dom";
// import PrivateRoute from "./PrivateRoute";

//pageloader
import PageLoader from "../Components/PageLoader";

const userData = localStorage.getItem("user");
const LazyHome = React.lazy(() => import("../Screens/Home"));
const LazySignIn = React.lazy(() => import("../Screens/Login"));
const LazySignUp = React.lazy(() => import("../Screens/Signup"));
const LazyOtp = React.lazy(() => import("../Screens/Otp"));
const LazyverifyOTP = React.lazy(() => import("../Screens/VerifyOTP"));
const LazyOrderHistory = React.lazy(() => import("../Screens/OrderHistory"));

const LazyDetail = React.lazy(() => import("../Screens/MagazineDetail"));
const LazyCategory = React.lazy(() => import("../Screens/CategoryMagazines"));
const LazyReading = React.lazy(() => import("../Screens/Reading"));
const Lazynotfound = React.lazy(() => import("../Screens/Pagenotfound"));
const LazySearchbar = React.lazy(() => import("../Components/Searchbar"));
const LazyProfile = React.lazy(() => import("../Screens/Profile"));
const LazyHardcopy = React.lazy(() => import("../Components/Hardcopy"));
const LazyBuy = React.lazy(() => import("../Components/Buynow"));
const LazyRent = React.lazy(() => import("../Components/Rentnow"));
const LazyYourMagazines = React.lazy(() => import("../Screens/YourMagazines"));
const LazyOffers = React.lazy(() => import("../Screens/Offers"));
const LazyFaq = React.lazy(() => import("../Screens/Faqs"));
const LazyContactUS = React.lazy(() => import("../Screens/Contactus"));
const LazySubscriptions = React.lazy(() =>
  import("../Screens/SubscriptionPlans")
);
const LazyBlog = React.lazy(() => import("../Screens/Blog"));
const LazyBlogTwo = React.lazy(() => import("../Screens/BlogTwo"));

const LazyOtherissues = React.lazy(() => import("../Components/OtherIssues"));

const LazyOrderDetail = React.lazy(() => import("../Screens/OrderDetail"));
const LazyDeliveryPolicy = React.lazy(() =>
  import("../Screens/DeliveryPolicy")
);
const LazyAboutUS = React.lazy(() => import("../Screens/Aboutus"));
const LazyCategories = React.lazy(() => import("../Screens/Categories"));
const LazyFreeRead = React.lazy(() => import("../Screens/FreeReading"));
const LazyBuySubscription = React.lazy(() =>
  import("../Components/Subscriptionavail")
);

const LazyPodcast = React.lazy(() => import("../Screens/Podcast"));
const LazyMobPodcast = React.lazy(() => import("../Screens/MobilePodcast"));
const LazyMobFaqs = React.lazy(() => import("../Screens/MobileFaqs"));
const LazyMobDeliveryPolicy = React.lazy(() =>
  import("../Screens/DeliveryPolicyMob")
);

const LazyTerm = React.lazy(() => import("../Screens/termconditions"));
const LazyViewAll = React.lazy(() => import("../Screens/ViewAll"));
const Naviagation = () => {
  const { state } = useLocation();

  return (
    <Routes>
      <Route
        path="/"
        element={
          <React.Suspense fallback={<PageLoader />}>
            <LazyHome />
          </React.Suspense>
        }
      />
      <Route
        path="/login"
        element={
          <React.Suspense fallback={<PageLoader />}>
            <LazySignIn />
          </React.Suspense>
        }
      />
      <Route
        path="/signup"
        element={
          <React.Suspense fallback={<PageLoader />}>
            {" "}
            <LazySignUp />{" "}
          </React.Suspense>
        }
      />
      {/* <Route element={<PrivateRoute />}> */}
      <Route
        path="/otp"
        element={
          <React.Suspense fallback={<PageLoader />}>
            {" "}
            <LazyOtp />{" "}
          </React.Suspense>
        }
      />
      <Route
        path="/verifyotp"
        element={
          <React.Suspense fallback={<PageLoader />}>
            <LazyverifyOTP />
          </React.Suspense>
        }
      />

      <Route
        path="/my-profile"
        element={
          <React.Suspense fallback={<PageLoader />}>
            {userData ? <LazyProfile /> : <Navigate to="/" />}
          </React.Suspense>
        }
      />

      <Route
        path="/my-magazines"
        element={
          <React.Suspense fallback={<PageLoader />}>
            {userData ? <LazyYourMagazines /> : <Navigate to="/" />}
          </React.Suspense>
        }
      />

      <Route
        path="/search"
        element={
          <React.Suspense fallback={<PageLoader />}>
            {" "}
            <LazySearchbar />{" "}
          </React.Suspense>
        }
      />

      <Route
        path={`/category/:categoryname/:catid`}
        element={
          <React.Suspense fallback={<PageLoader />}>
            <LazyCategory />
          </React.Suspense>
        }
      />

      {state != null ? (
        <Route
          path={`/magazineDetail/:name/:id`}
          element={
            <React.Suspense fallback={<PageLoader />}>
              {" "}
              <LazyDetail />{" "}
            </React.Suspense>
          }
        />
      ) : (
        <>
          {" "}
          <Route
            path={`/magazineDetail/:name/:id`}
            element={
              <React.Suspense fallback={<PageLoader />}>
                {" "}
                <LazyDetail />{" "}
              </React.Suspense>
            }
          />
        </>
      )}
      <Route
        path="/Reading"
        element={
          <React.Suspense fallback={<PageLoader />}>
            {" "}
            {userData ? <LazyReading /> : <Navigate to="/" />}
          </React.Suspense>
        }
      />

      <Route
        path={`/buy-hardcopy/:name/:id`}
        element={
          <React.Suspense fallback={<PageLoader />}>
            <LazyHardcopy />
          </React.Suspense>
        }
      />

      <Route
        path={`/buymagazine/:name/:id`}
        element={
          <React.Suspense fallback={<PageLoader />}>
            <LazyBuy />
          </React.Suspense>
        }
      />
      <Route
        path={`/rentmagazine/:name/:id`}
        element={
          <React.Suspense fallback={<PageLoader />}>
            <LazyRent />
          </React.Suspense>
        }
      />

      <Route
        path="/order-history"
        element={
          <React.Suspense fallback={<PageLoader />}>
            {/* {userData ? <LazyOrderHistory /> : <Navigate to="/" />} */}
            <LazyOrderHistory />{" "} 
          </React.Suspense>
        }
      />
      {/* <Route
        path="/offers"
        element={
          <React.Suspense fallback={<PageLoader />}>
            {userData ? <LazyOffers /> : <Navigate to="/" />}
          </React.Suspense>
        }
      /> */}

{/* <Route
        path="/offers"
        element={
          <React.Suspense fallback={<PageLoader />}>
            {userData ? <LazyOffers /> : <Navigate to="/" />}
          </React.Suspense>
        }
      /> */}

<Route
        path="/offers"
        element={
          <React.Suspense fallback={<PageLoader />}>
            {/* {userData ? <LazyOffers /> : <Navigate to="/" />} */}
           
            {userData ? <LazyOffers /> : <Navigate to="/"  />}
          </React.Suspense>
        }
      />

 
      <Route
        path="/faq"
        element={
          <React.Suspense fallback={<PageLoader />}>
            <LazyFaq />{" "}
          </React.Suspense>
        }
      />

      <Route
        path="/delivery-policy"
        element={
          <React.Suspense fallback={<PageLoader />}>
            <LazyDeliveryPolicy />{" "}
          </React.Suspense>
        }
      />
      <Route
        path="/contact-us"
        element={
          <React.Suspense fallback={<PageLoader />}>
            <LazyContactUS />{" "}
          </React.Suspense>
        }
      />
      <Route
        path="/subscriptions"
        element={
          <React.Suspense fallback={<PageLoader />}>
            {userData ? <LazySubscriptions /> : <Navigate to="/" />}
          </React.Suspense>
        }
      />
      <Route
        path="/blog"
        element={
          <React.Suspense fallback={<PageLoader />}>
            <LazyBlog />{" "}
          </React.Suspense>
        }
      />

      <Route
        path="/blogtwo"
        element={
          <React.Suspense fallback={<PageLoader />}>
            <LazyBlogTwo />{" "}
          </React.Suspense>
        }
      />

      {/* <Route
        path={`/:name/order-detail`}
        element={
          <React.Suspense fallback={<PageLoader />}>
            <LazyOrderDetail />{" "}
          </React.Suspense>
        }
      /> */}


       <Route
        path={`/:name/order-detail/:id`}
        element={
          <React.Suspense fallback={<PageLoader />}>
            {/* <LazyOrderDetail />{" "} */}
            {userData ?  <LazyOrderDetail /> : <Navigate to="/" />}
          </React.Suspense>
        }
      />
      <Route
        path={`/About-us`}
        element={
          <React.Suspense fallback={<PageLoader />}>
            <LazyAboutUS />
          </React.Suspense>
        }
      />
      <Route
        path={`/Categories`}
        element={
          <React.Suspense fallback={<PageLoader />}>
            <LazyCategories />
          </React.Suspense>
        }
      />
      <Route
        path={`/free-read`}
        element={
          <React.Suspense fallback={<PageLoader />}>
            <LazyFreeRead />
          </React.Suspense>
        }
      />
      <Route
        path={`/buy-subscription`}
        element={
          <React.Suspense fallback={<PageLoader />}>
            <LazyBuySubscription />
          </React.Suspense>
        }
      />

      {/* podcast */}
      <Route
        path={`/mfah-podcasts`}
        element={
          <React.Suspense fallback={<PageLoader />}>
            <LazyPodcast />
          </React.Suspense>
        }
      />

      <Route
        path={`/mfah-mobilepodcasts`}
        element={
          <React.Suspense fallback={<PageLoader />}>
            <LazyMobPodcast />
          </React.Suspense>
        }
      />
      <Route
        path={`/mfah-mobile-faqs`}
        element={
          <React.Suspense fallback={<PageLoader />}>
            <LazyMobFaqs />
          </React.Suspense>
        }
      />

      <Route
        path={`/delivery-policy-mobs`}
        element={
          <React.Suspense fallback={<PageLoader />}>
            <LazyMobDeliveryPolicy />
          </React.Suspense>
        }
      />
      {/* </Route> */}
      <Route
        path="*"
        element={
          <React.Suspense fallback={<PageLoader />}>
            <Lazynotfound />{" "}
          </React.Suspense>
        }
      />
      <Route
        path={`/Otherissues`}
        element={
          <React.Suspense fallback={<PageLoader />}>
            <LazyOtherissues />
          </React.Suspense>
        }
      />
      <Route
        path={`/:magSystemCat/:magSysCatID`}
        element={
          <React.Suspense fallback={<PageLoader />}>
            {" "}
            <LazyViewAll />
          </React.Suspense>
        }
      />
      <Route
        path={`/Term`}
        element={
          <React.Suspense fallback={<PageLoader />}>
            <LazyTerm />
          </React.Suspense>
        }
      />
    </Routes>
  );
};

export default Naviagation;

import React from 'react';
import ReactDOM from 'react-dom/client';
import {  HashRouter } from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';



axios.interceptors.request.use((request) => {
  let token = localStorage.getItem("ACCESS_TOKEN");
  if ((request.url.includes('user_login/') && !token ) || (request.url.includes('verifyOTP/') ) || (request.url.includes('user_register/') &&!token) || request.url.includes('magazine_contactus/') && !token  || (request.url.includes("MagazinebySystem_category/") && !token) || (request.url.includes('social_login/') && !token) || (request.url.includes('resend_otp/') && !token) || (request.url.includes('magazine_category/') && !token) || (request.url.includes('countrycode_api/') && !token) || (request.url.includes('magazine_search/') && !token )|| (request.url.includes('user_register/') && !token) || (request.url.includes('magazine_detail/') && !token) || (request.url.includes("magazine_subscription_Info/") && !token )|| (request.url.includes("magazine_review_history/") && !token ) || (request.url.includes("magazine_offer_list/") && !token) || request.url.includes("promotional_magazine/") && !token || (request.url.includes("magazine_subscription_list/")) ) 
      return request;
  
  request.headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json' || 'multipart/form-data'
  }

  return request
})


axios.interceptors.response.use(
  (response) => {
      return response
  }, (error) => {
    if (error.response) {
        if(error.response.status === 401){
          // let navigate = useNavigate()
            localStorage.clear();
            sessionStorage.clear();
            // navigate('/')
            // window.location.reload();
        }
        else{
          return Promise.reject(error);
        }
        
      }
      return Promise.reject(error);
  }
)



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <HashRouter >
    <App />
  </HashRouter>  
  </React.StrictMode>
);


